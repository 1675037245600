// extracted by mini-css-extract-plugin
export var title = "cart-module--title--3qvMZ";
export var table = "cart-module--table--1GgUd";
export var wrap = "cart-module--wrap--1mWUB";
export var totals = "cart-module--totals--1cbPs";
export var summary = "cart-module--summary--3DX23";
export var grandTotal = "cart-module--grandTotal--ysUv3";
export var checkoutButton = "cart-module--checkoutButton--1__7z";
export var labelColumn = "cart-module--labelColumn--2HVtO";
export var collapseColumn = "cart-module--collapseColumn--2by3x";
export var productHeader = "cart-module--productHeader--39qTp";
export var imageHeader = "cart-module--imageHeader--3VFrw";
export var emptyStateContainer = "cart-module--emptyStateContainer--WryLY";
export var emptyStateHeading = "cart-module--emptyStateHeading--_2NIQ";
export var emptyStateLink = "cart-module--emptyStateLink--i3esU";